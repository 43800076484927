<template>
  <div class="container">
    <div class="title">2024年陕西省职业教育活动周启动仪式</div>

    <van-grid :column-num="3" border>
      <van-grid-item  icon="feedback" text="会议报名" style="color:#2b72c0;" @click="handleClick('/user/register')"/>
      <van-grid-item  icon="user-o" text="在线签到" style="color:#2b72c0;"  @click="handleClick('/user/sign')"/>
      <van-grid-item  icon="calendar" text="会议议程" style="color:#2b72c0;" @click="handleClick('/user/liucheng')"/>
      <van-grid-item  icon="comment" text="会议手册" style="color:#2b72c0;" @click="handleClick('/user/shouce')"/>
      <van-grid-item  icon="warning-o" text="温馨提示" style="color:#2b72c0;" @click="handleClick('/user/wenxintishi')"/>
      <van-grid-item  icon="location-o" text="座位图" style="color:#2b72c0;" @click="handleClick('/user/zuoweitu')"/>
    </van-grid>
    <van-image width="100%"  src="/meeting.jpg" />
  </div>
</template>

<script>
import { Toast } from 'vant-green';

export default {
  data() {
    return {
      active: 0,
    };
  },
  methods:{
    handleClick(action){
      console.log('user.action',action)
      if(action=='unfinish'){
        Toast('尚未开放，请您耐心等候!')
      }else if(action=='finished'){
        Toast('已经超过截止时间，感谢您的访问!')
      }else{
        this.$router.push(action)
      }
    }
  }
};
</script>

<style scoped>
.container{
  padding: 0 2px;
}
.title{
  line-height: 45px;
  text-align: center;
  background-color: #fff;
  font-size: 18px;
  font-weight: bolder;
  border-bottom: 1px solid #c0c0c0;
}
</style>